@import 'theme';

a.item {
	display: flex;
	gap: 0.5rem;

	height: 3rem;

	flex-direction: row;
	align-items: center;

	font-family: $font-px-grotesk;
	font-size: 1rem;
	line-height: 1.5rem;
	font-style: normal;
	font-weight: 400;

	border-radius: 0.5rem;
	color: var(--neo-color-global-content-neutral-moderate);
	text-decoration: none;
	padding: 0.5rem 0.75rem;
	background-color: var(--neo-color-global-background-static-transparent);
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
		opacity, box-shadow, transform, filter, backdrop-filter;
	transition-duration: 150ms;
	transition-timing-function: ease-in-out;
	white-space: nowrap;

	.icon {
		display: block;
		aspect-ratio: 1;
		height: 1.5rem;
		width: 1.5rem;
		background-color: var(--neo-color-global-content-neutral-moderate);
		mask-repeat: no-repeat;
		mask-position: center;
		transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
			opacity, box-shadow, transform, filter, backdrop-filter;
		transition-duration: 150ms;
		transition-timing-function: ease-in-out;
	}

	@media (min-width: $breakpoint-width-xl) {
		&.open {
			justify-content: center;
		}
	}

	@media (min-width: $breakpoint-width-xl) {
		&.slim {
			row-gap: 0;

			.text {
				display: none;
			}
		}
	}

	&:hover {
		background-color: var(--neo-color-global-background-primary-soft-hover);
		color: var(--neo-color-global-content-neutral-moderate);
	}

	&:focus-visible {
		box-shadow: 0 0 0 0.1875rem var(--neo-color-global-border-static-focus) inset;
	}

	&.icon {
		font-weight: bold;
		padding: 0.5rem 0.5rem 0.5rem 0.75rem;
	}

	&.invertColors {
		font-weight: 700;

		&.inGroup {
			font-weight: 400;
		}

		&.active {
			font-weight: 700;
		}
	}

	&.active {
		background: var(--neo-color-global-background-primary-soft-active);
		color: var(--neo-color-global-content-primary-intense);

		.icon {
			background: var(--neo-color-global-content-primary-intense);
		}
	}
}

.pillContainer {
	display: flex;
	align-items: center;
}
