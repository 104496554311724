@import 'theme';

.sipgatestatus.dropdown {
	--background-color: var(--neo-color-global-background-neutral-soft-default);
	--font-color: var(--neo-color-global-content-neutral-intense);

	display: flex;
	align-items: flex-end;
	justify-content: center;
	flex-direction: column;
	font-family: $font-px-grotesk;

	button,
	.statusButton {
		display: inline-flex;
		padding: 0.5rem 1rem;
		align-items: center;
		gap: 0.5rem;
		border-radius: 0.25rem;
		background: var(--background-color);
		color: var(--font-color);
		outline: inherit;
		text-align: left;
		font-size: 1rem;
		font-style: normal;
		font-weight: 700;
		line-height: 1.5rem;
		transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
			opacity, box-shadow, transform, filter, backdrop-filter;
		transition-duration: 150ms;
		transition-timing-function: ease-in-out;

		&:focus-visible {
			box-shadow: 0 0 0 0.1875rem var(--neo-color-global-border-static-focus) inset;
			border-color: var(--neo-color-global-border-static-focus);
		}

		&:hover {
			--background-color: var(--neo-color-global-background-neutral-soft-hover);
		}

		&:active {
			--background-color: var(--neo-color-global-background-neutral-soft-active);
		}
	}

	.status {
		top: 4rem;
		max-width: 18rem;
		width: 18rem;
		color: var(--font-color);
		display: flex;
		flex-direction: column;
		gap: 1rem;

		background: var(--background-color);
		font-size: 1rem;
		font-style: normal;
		font-weight: 400;
		line-height: 1.25rem;

		.header {
			align-self: start;
			padding: 1rem 1rem 0 1rem;
			&.inverted {
				filter: invert(1);
			}
		}

		.content {
			padding: 0 1rem 0 1rem;
			.headline {
				font-size: 1rem;
				word-wrap: anywhere;
				margin: 0;
			}

			p {
				margin: 0.5rem 0;
			}

			.timestamp {
				font-size: 0.75rem;
				font-style: normal;
				font-weight: 400;
				line-height: 1rem;
			}
		}

		.statusLink {
			border-top: 0.0625rem solid var(--neo-color-global-border-neutral-soft-default);
			padding: 1rem;
			background: var(--neo-color-global-surface-menu);
			text-align: end;
		}
	}

	&:not(.none) {
		.headline {
			font-weight: bold;
		}
	}

	&.minor {
		.status {
			--background-color: var(--neo-color-global-surface-warning);
			--font-color: var(--neo-color-global-content-warning-intense);
		}
	}

	&.major {
		.status {
			--background-color: var(--neo-color-global-surface-critical);
			--font-color: var(--neo-color-global-content-critical-intense);
		}
	}

	&.maintenance {
		.status {
			--background-color: var(--neo-color-global-surface-info);
			--font-color: var(--neo-color-global-content-info-intense);
		}
	}

	&.none {
		.status {
			--background-color: var(--neo-color-global-surface-success);
			--font-color: var(--neo-color-global-content-success-intense);
		}
	}

	&.noDescription {
		.status {
			.description {
				display: none;
			}
		}
	}

	&.noTimestamp {
		.status {
			.timestamp {
				display: none;
			}
		}
	}
}

.sipgatestatus.button {
	--background-color: var(--neo-color-global-background-neutral-soft-default);
	--font-color: var(--neo-color-global-content-neutral-intense);

	&.colorInverted {
		--external-icon: url('./img/external-gray.svg');
	}

	&:not(.colorInverted) {
		--external-icon: url('./img/external.svg');
	}

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-items: center;
	padding: 1rem;
	gap: 0.75rem;
	width: 100%;

	color: var(--font-color);
	font-family: $font-px-grotesk;
	font-size: 1rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1.5rem;
	text-decoration: none;

	background-color: var(--background-color);

	&:after {
		content: var(--external-icon);
		margin-left: auto;
		height: 1.125rem;
	}

	@media (min-width: $breakpoint-width-md) {
		display: none;
	}
}
