@import 'theme';

.WebphoneButtonContainer {
	container-type: inline-size;
	display: flex;

	@include isSmallerThanMediumDevice {
		display: none;
	}

	.WebphoneButton {
		@include focussable;

		display: flex;
		flex: 1;
		justify-content: center;
		align-items: center;
		min-height: 3rem;
		min-width: 3rem;
		padding: 0.5rem 1rem;
		margin: 0;
		background: var(--neo-color-global-background-primary-intense-default);
		border-radius: 0.5rem;
		transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
			opacity, box-shadow, transform, filter, backdrop-filter;
		transition-duration: 150ms;
		transition-timing-function: ease-in-out;

		cursor: pointer;

		&:hover {
			background: var(--neo-color-global-background-primary-intense-hover);
		}

		&:active {
			background: var(--neo-color-global-background-primary-intense-active);
		}

		img {
			width: 1.5rem;
			height: 1.5rem;
		}

		@media (pointer: coarse) {
			padding: 0.75rem 0;
		}

		.WebphoneButtonText {
			display: none;
		}
	}

	@container (min-width: 16rem) {
		.WebphoneButton {
			margin: 0;

			.WebphoneButtonText {
				display: block;
				margin-left: 0.5rem;
				color: var(--neo-color-global-content-primary-on-intense);
				font-family: $font-px-grotesk;
				font-weight: 700;
			}
		}
	}
}
