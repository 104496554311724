.shapeImage {
	width: 66%;

	/*
        @include isMinMediumDevice ist hier nicht möglich - wir müssen den Breakpoint leider hart kodieren
    */
	@media (min-width: 768px) {
		width: clamp(8rem, 12vw, 10rem);
	}
}
