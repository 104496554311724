@import 'theme';

.icon {
	& > svg > g {
		fill: none;
		stroke-width: 3;
		stroke-linecap: round;
	}
}

.default {
	& > svg > g {
		stroke: var(--gray-600);
	}
}

.active {
	& > svg > g {
		stroke: var(--blue-500);
	}
}
