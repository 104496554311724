@import 'theme';

$breakpoint-shorten-middle: 22.5rem;

.container {
	height: 2rem;
	display: inline-flex;
	align-items: center;
	font-family: $font-px-grotesk;
	font-size: 0.75rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 100%;

	&:last-child {
		overflow: hidden;

		.breadcrumbLink {
			font-weight: 700;
			overflow: hidden;
			text-overflow: ellipsis;
			display: block;
		}
	}

	@media (max-width: $breakpoint-shorten-middle) {
		&:not(:first-child):not(:last-child) {
			.shortLink {
				display: block;
			}

			.breadcrumbLink {
				span {
					display: none;
				}
			}
		}
	}

	&:first-child {
		&:before {
			display: none;
		}
	}

	&:before {
		width: 1.5rem;
		height: 1.5rem;
		aspect-ratio: 1;
		content: url('../../accountNavigation/img/chevron-right.svg');
	}
}

.shortLink {
	display: none;
}

.breadcrumbLink {
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	border: 0.1875rem solid transparent;
	transition-property: border-color, color, fill, stroke;
	transition-duration: 150ms;
	transition-timing-function: ease-in-out;
	border-radius: 0.125rem;
	color: var(--neo-color-global-content-neutral-moderate);
	font-family: $font-px-grotesk;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 700;
	line-height: 1rem;
	padding: 0;
	margin: 0;

	&:hover {
		color: var(--neo-color-global-content-link-hover);
		text-decoration: underline;
	}
	&:active {
		color: var(--neo-color-global-content-link-active);
	}

	&:focus-visible {
		border: 0.1875rem solid var(--neo-color-global-border-static-focus);
	}
}
