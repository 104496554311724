@import 'theme';

.select {
	position: relative;
	display: flex;
	align-items: center;

	background-color: var(--neo-color-web-app-surface-app);
	background-image: url('../media/icons/icon-triangle_down-dark-solid-default-16.svg');
	background-repeat: no-repeat;
	background-position: right 16px top 50%;
	background-size: 12px;

	border: 1px solid var(--neo-color-global-border-neutral-intense-default);
	border-radius: 2px;
	padding: 7px 36px 7px 16px;

	transition: box-shadow 150ms;

	&:focus-within {
		box-shadow: var(--neo-color-global-border-static-focus) 0px 0px 0px 3px inset;
	}

	&:hover {
		background-color: var(--neo-color-global-background-primary-soft-hover);
	}
}

.select select {
	cursor: pointer;
	appearance: none;

	position: absolute;
	left: 1px;
	top: 0;
	right: 1px;
	bottom: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
}
