@import 'theme';

.nqnupsi {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	flex-direction: column;

	.toggleButton {
		display: flex;
		align-items: center;
		justify-content: center;
		background: none;
		color: var(--neo-color-global-content-neutral-moderate);
		width: 2.5rem;
		height: 2.5rem;
		padding: 0;

		border-radius: 0.25rem;
		border: none;
		outline: inherit;
		text-align: left;
		transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
			opacity, box-shadow, transform, filter, backdrop-filter;
		transition-duration: 150ms;
		transition-timing-function: ease-in-out;

		&:hover {
			background-color: var(--neo-color-global-background-neutral-soft-hover);
		}

		&:active {
			background-color: var(--neo-color-global-background-neutral-soft-active);
		}

		&:focus-visible {
			box-shadow: 0 0 0 0.1875rem var(--neo-color-global-border-static-focus) inset;
		}
	}

	.withToggle {
		padding-left: 1rem;
	}

	.theme {
		padding: 0.5rem 1rem;
		color: var(--neo-color-global-content-neutral-intense);
	}
}

.nqNupsiIcon {
	height: 1.5rem;
	width: 1.5rem;
	mask-image: url(./img/icon-nq-dark-line-default.svg);
	mask-size: contain;
	mask-repeat: no-repeat;
	mask-position: center;

	background-color: currentcolor;
	/* Prevent background leakage on transition in Firefox */
	backface-visibility: hidden;
}
