@import 'theme';

.selectAll {
	display: flex;
	background-color: var(--neo-color-web-app-surface-app);
	z-index: 1;
	white-space: nowrap;

	button.opener {
		border: none;
		background: var(--neo-color-global-background-static-transparent);
		padding: 0;

		&:focus-visible .selectGroup {
			box-shadow: var(--neo-color-global-border-static-focus) 0 0 0 0.1875rem inset;
		}
	}

	// Hide sliding-in action buttons
	margin-right: -0.5rem;
	padding-right: 0.5rem;

	.selectGroup {
		margin-left: 0.25rem;
		border-radius: 0.25rem;
		vertical-align: middle;
		transition: box-shadow 150ms;

		.caret {
			padding: 0.75rem 0.25rem;
			pointer-events: initial;
			@media (max-width: $breakpoint-width-md) {
				padding-right: 0;
			}
		}

		.checkboxHighlight {
			padding: 0 0.75rem;
		}

		.caret,
		.checkboxHighlight {
			height: 2.5rem;
			border-radius: 0.25rem;
			vertical-align: middle;
			display: inline-block;

			&:hover {
				background-color: var(--neo-color-global-background-neutral-moderate-hover);
			}
		}

		&:hover {
			@media (max-width: $breakpoint-width-md) {
				background-color: var(--neo-color-global-background-neutral-moderate-hover);
			}
		}
	}

	.selectGroup.open {
		background-color: var(--neo-color-web-app-component-card-background-default);

		.caret,
		.checkboxHighlight {
			&:hover {
				background-color: var(--neo-color-global-background-neutral-moderate-hover);
			}
		}
	}
}

.mobileCheckbox {
	display: none;

	@media (max-width: $breakpoint-width-md) {
		display: inline-block;
	}
}

.desktopCheckbox {
	@media (max-width: $breakpoint-width-md) {
		display: none;
	}
}
