@import 'theme';

$duration: 400ms;
$easing: cubic-bezier(0.165, 0.84, 0.44, 1);

.rootContainer {
	display: flex;
	flex-direction: column;
	height: 100%;

	&.affiliateImpersonation {
		border: 0.5rem solid var(--neo-color-global-border-info-intense-default);
	}

	&.helpdeskImpersonation {
		border: 0.5rem solid var(--neo-color-global-border-warning-intense-default);

		&.neoPBX {
			border-color: var(--neo-color-global-background-electric-intense-default);
		}
	}
}

.childContainer {
	display: flex;
	flex-direction: column;

	&:first-child {
		flex-grow: 1;
	}

	color: var(--neo-color-global-content-neutral-intense);
	background-color: var(--neo-color-web-app-surface-app);
	overflow: auto;
	flex: 1 1 100%;
	line-height: 1;

	> * {
		flex-grow: 1;
		max-width: 100%;
	}
}

.container {
	overflow: hidden;
	display: flex;
	flex-direction: row;
	flex-grow: 1;

	.stickyContainer {
		width: 100%;
		display: flex;
		flex-direction: column;
		overflow: auto;
		flex: 1 1 auto;
	}
}

.igelPadding {
	height: 100%;
}

.skipToContentLink:focus-visible,
.skipToContentLink:focus {
	position: static;
	clip: initial;
	height: auto;
	width: auto;
	font-weight: bold;
	text-decoration: none;
	background-color: var(--neo-color-global-border-static-focus);
	color: var(--neo-color-global-content-critical-on-intense);
	position: fixed;
	z-index: 9999;
	padding: 0.75rem;
	margin: 0.75rem;
	border-radius: 0.25rem;
}

.content {
	height: 100%;
	padding: 0 1rem;

	@media (min-width: $breakpoint-width-md) {
		padding: 0 2.5rem;
	}

	@media (min-width: $breakpoint-width-lg) {
		padding: 0 2.5rem;
	}

	@media (min-width: $breakpoint-width-xl) {
		padding: 0 5rem;
	}
}

.impersonationOverlay {
	position: fixed;
	bottom: 0;
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
	padding: 0.375rem 1.5rem;
	color: var(--neo-color-global-content-neutral-on-intense);
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-family: $font-px-grotesk;
	left: 50%;
	transform: translate(-50%, 0);

	@media (max-width: $breakpoint-width-sm) {
		left: 0;
		width: 100%;
		text-align: center;
		transform: none;
		padding: 0.75rem 1.5rem;
	}
}

.affiliateImpersonationOverlay {
	background-color: var(--neo-color-global-background-info-intense-default);
}

.helpdeskImpersonationOverlay {
	background-color: var(--neo-color-global-background-warning-intense-default);

	&.neoPBX {
		background-color: var(--neo-color-global-background-electric-intense-default);
	}
}
